.gradient {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-to: #3b82f6;
    background-image: linear-gradient(to right, var(--tw-gradient-from), var(--tw-gradient-to, rgb(67 56 202 / 0)));
  }

.gradient2 {
  --tw-gradient-from: #030225;
  --tw-gradient-to: #3C0F68;
  background-image: linear-gradient(to right, var(--tw-gradient-from), var(--tw-gradient-to, rgb(67 56 202 / 0)));
}

.capitalize::first-letter {
  text-transform: uppercase;
}